<template>
  <PageWithLayout :isBodyBg="false">
    <div class="group_box">
      <BoxView title="Calendar" :grid="100">
        <CalendarComponent
          :calendarData="calendarDefaultData">
        </CalendarComponent>
      </BoxView>
      <BoxView title="Calendar - statistic" :grid="100">
        <CalendarComponent
          :calendarData="calendarStatisticData"
          :dataList="statisticDataList">
          <template v-slot:infoNotes>
            <span class="item_notes">
              <IconSvg iconName="email" :size="13" iconColor ="#fff" bgcolor="#86BEFF"/>email
            </span>
            <span class="item_notes">
              <IconSvg iconName="phone" :size="13" iconColor ="#fff" bgcolor="#6CDFC3"/>phone
            </span>
          </template>
          <template v-slot:calendarDayBody="slotProps">
            <dl
              :class="[{ disabled : !slotProps.dayItemData.emailCount || slotProps.dayItemData.emailCount === 0 }]">
              <dt><IconSvg iconName="email" :size="13" iconColor ="#fff" bgcolor="#86BEFF"/></dt>
              <dd>{{ slotProps.dayItemData.emailCount }}</dd>
            </dl>
            <dl
              :class="[{ disabled : !slotProps.dayItemData.phoneCount || slotProps.dayItemData.phoneCount === 0 }]">
              <dt><IconSvg iconName="phone" :size="13" iconColor ="#fff" bgcolor="#6CDFC3"/></dt>
              <dd>{{ slotProps.dayItemData.phoneCount }}</dd>
            </dl>
            <dl
              :class="['item_total', { disabled : ( !slotProps.dayItemData.phoneCount && !slotProps.dayItemData.emailCount ) || slotProps.dayItemData.phoneCount + slotProps.dayItemData.emailCount === 0 }]">
              <dt>total</dt>
              <dd>{{ slotProps.dayItemData.phoneCount + slotProps.dayItemData.emailCount || 0 }}</dd>
            </dl>
          </template>
        </CalendarComponent>
      </BoxView>
      <BoxView title="Calendar - schedule" :grid="100">
        <CalendarComponent
          :calendarData="calendarScheduleData"
          :scheduleList="scheduleDataList"
          @onAddSchedule="date => onAddSchedule(date)"
          @getData="getData()">
          <template v-slot:infoNotes>
            <span class="item_notes">
              <span class="color_notes" :style="`background-color:${chartColorData.yellow}`"></span>공통
            </span>
            <span class="item_notes">
              <span class="color_notes" :style="`background-color:${chartColorData.darkBulue}`"></span>컨텐츠
            </span>
            <span class="item_notes">
              <span class="color_notes" :style="`background-color:${chartColorData.primary}`"></span>App
            </span>
            <span class="item_notes">
              <span class="color_notes" :style="`background-color:${chartColorData.green}`"></span>Landing Page
            </span>
            <span class="item_notes">
              <span class="color_notes" :style="`background-color:${chartColorData.red}`"></span>은행
            </span>
            <span class="item_notes">
              <span class="color_notes" :style="`background-color:${chartColorData.purple}`"></span>공휴일
            </span>
          </template>
        </CalendarComponent>
      </BoxView>
      <BoxView title="Calendar - schedule range" :grid="100">
        <CalendarComponent
          :calendarData="calendarScheduleRangeData"
          :scheduleRangeList="scheduleRangeDataList"
          @onAddSchedule="date => onAddSchedule(date)"
          @getData="getData()">
        </CalendarComponent>
      </BoxView>
    </div>
    <template v-slot:popup>
      <SchedulePopup
        v-if="isSchedulePopup"
        :date="addScheduleDate"
        @onClickClose="onClickCloseSchedulePopup()"
        @onClickComplete="scheduleData => onClickCompleteSchedulePopup(scheduleData)"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BoxView from '@lemontree-ai/lemontree-admin-common-front/components/common/boxView/BoxView';
import CalendarComponent from '@lemontree-ai/lemontree-admin-common-front/components/common/calendar/Calendar';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import { chartColorData } from '@lemontree-ai/lemontree-admin-common-front/constants/chartColorData'

import SchedulePopup from '@lemontree-ai/lemontree-admin-common-front/components/common/calendar/popup/SchedulePopup';

export default {
  name:'Calendar',
  components:{
    PageWithLayout,
    BoxView,
    CalendarComponent,
    IconSvg,
    SchedulePopup
  },
  data(){
    return{
      calendarDefaultData: {
        targetMonth: '', // 월간 - 기준월 YYYY-MM
        searchDataRangeMonthly: { start_date:'', end_date:'' }, // 월간 조회 일
        limit: { minMonth:'', maxMonth:'', isMaxMonthToday:false }, // 월간 - 기준 최소시작일
      },
      calendarStatisticData: {
        targetMonth: '', // 월간 - 기준월 YYYY-MM
        searchDataRangeMonthly: { start_date:'', end_date:'' }, // 월간 조회 일
        limit: { minMonth:'2021-10', maxMonth:'', isMaxMonthToday:true }, // 월간 - 기준 최소시작일
        isData: true,
      },
      statisticDataList:[
        {date:'2022-01-02', emailCount:0, phoneCount:1},
        {date:'2022-01-10', emailCount:4, phoneCount:6},
        {date:'2022-01-12', emailCount:2, phoneCount:0},
      ],
      calendarScheduleData: {
        targetMonth: '2022-01', // 월간 - 기준월 YYYY-MM
        searchDataRangeMonthly: { start_date:'', end_date:'' }, // 월간 조회 일
        limit: { minMonth:'2021-10', maxMonth:'', isMaxMonthToday:false }, // 월간 - 기준 최소시작일
        isSchedule: true,
        isShowFootFix: true,
      },
      calendarScheduleRangeData: {
        targetMonth: '2022-07', // 월간 - 기준월 YYYY-MM
        searchDataRangeMonthly: { start_date:'', end_date:'' }, // 월간 조회 일
        limit: { minMonth:'', maxMonth:'', isMaxMonthToday:false }, // 월간 - 기준 최소시작일
        isSchedule: true,
        isShowFootFix: true,
      },
      scheduleDataList:[],
      scheduleRangeDataList:[],
      isSchedulePopup: false,
    }
  },
  computed:{
    chartColorData(){
      return chartColorData;
    },
  },
  methods:{
    getData(){
      this.scheduleDataList = [
        {
          date:'2021-10-26',
          scheduleDataList:[
            { id:1, title:'🎉 Landing Page 오픈일', type:'APP', date:'2021-10-26', createdAt:"2022-01-24T12:05:01", updatedAt:"2022-01-24T12:05:01", writer:{ id: 8, name:'유보현', email:'kerry@lemontree.ai', image:'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_kerry.png' } },
          ]
        },
        {
          date:'2021-12-27',
          scheduleDataList:[
            { title:'전금업 라이선스 취득', type:'GENERAL', date:'2021-12-27', createdAt:"2022-01-24T12:05:01", updatedAt:"2022-01-24T12:05:01", writer:{ id: 8, name:'유보현', email:'kerry@lemontree.ai', image:'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_kerry.png' } },
          ]
        },
        {
          date:'2022-01-01',
          scheduleDataList:[
            { title:'🏦 하나은행 긴급점검', type:'BANK', date:'2022-01-01', createdAt:"2022-01-24T12:05:01", updatedAt:"2022-01-24T12:05:01", writer:{ id: 8, name:'유보현', email:'kerry@lemontree.ai', image:'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_kerry.png' } },
          ]
        },
        {
          date:'2022-01-03',
          scheduleDataList:[
            { title:'📽 그랜드오픈 레몬 live', type:'CONTENT', date:'2022-01-03', createdAt:"2022-01-24T12:05:01", updatedAt:"2022-01-24T12:05:01", writer:{ id: 8, name:'유보현', email:'kerry@lemontree.ai', image:'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_kerry.png' } },
            { title:'🎉 App 그랜드오픈', desc:'App 그랜드오픈', type:'APP', createdAt:"2022-01-24T12:05:01", updatedAt:"2022-01-24T12:05:01", writer:{ id: 8, name:'유보현', email:'kerry@lemontree.ai', image:'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_kerry.png' } },
          ]
        },
        {
          date:'2022-01-10',
          scheduleDataList:[
            { title:'MONEY QUIZ 추가', type:'PAGE', date:'2022-01-10', createdAt:"2022-01-24T12:05:01", updatedAt:"2022-01-24T12:05:01", writer:{ id: 8, name:'유보현', email:'kerry@lemontree.ai', image:'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_kerry.png' } },
          ]
        },
        {
          date:'2022-01-16',
          scheduleDataList:[
            { title:'🏦 국민은행 정기점검', type:'BANK', date:'2022-01-16', createdAt:"2022-01-24T12:05:01", updatedAt:"2022-01-24T12:05:01", writer:{ id: 8, name:'유보현', email:'kerry@lemontree.ai', image:'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_kerry.png' } },
          ]
        },
        {
          date:'2022-01-21',
          scheduleDataList:[
            { title:'레몬카드 릴리즈', type:'GENERAL', date:'2022-01-21', createdAt:"2022-01-24T12:05:01", updatedAt:"2022-01-24T12:05:01", writer:{ id: 8, name:'유보현', email:'kerry@lemontree.ai', image:'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_kerry.png' } },
          ]
        },
        {
          date:'2022-01-24',
          scheduleDataList:[
            { title:'스탠다드 요금제 오픈', type:'GENERAL', date:'2022-01-24', createdAt:"2022-01-24T12:05:01", updatedAt:"2022-01-24T12:05:01", writer:{ id: 8, name:'유보현', email:'kerry@lemontree.ai', image:'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_kerry.png' } },
          ]
        },
        {
          date:'2022-01-28',
          scheduleDataList:[
            { title:'📊 레몬퀴즈 정기 진단리포트 공유일', type:'CONTENT', 
           createdAt:"2022-01-24T12:05:01", updatedAt:"2022-01-24T12:05:01", writer:{ id: 8, name:'유보현', email:'kerry@lemontree.ai', image:'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_kerry.png' } },
          ]
        },
        {
          date:'2022-01-31',
          scheduleDataList:[
            { title:'🏦 신한은행 정기점검', type:'BANK', date:'2022-01-31', createdAt:"2022-01-24T12:05:01", updatedAt:"2022-01-24T12:05:01", writer:{ id: 8, name:'유보현', email:'kerry@lemontree.ai', image:'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_kerry.png' } },
          ]
        },
      ];
      this.scheduleRangeDataList = [
        { id:1, title:'정기정검', type:'APP', startAt:'2022-07-12T23:00:00', endAt:'2022-07-13T01:00:00', createdAt:"2022-01-24T12:05:01", updatedAt:"2022-01-24T12:05:01", writer:{ id: 8, name:'유보현', email:'kerry@lemontree.ai', image:'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_kerry.png' } },
        { id:2, title:'정기정검', type:'APP', startAt:'2022-07-11T23:00:00', endAt:'2022-07-12T23:00:00', createdAt:"2022-01-24T12:05:01", updatedAt:"2022-01-24T12:05:01", writer:{ id: 8, name:'유보현', email:'kerry@lemontree.ai', image:'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_kerry.png' } },
      ]
    },
    onAddSchedule(date){
      this.addScheduleDate = date;
      this.isSchedulePopup = true;
    },
    onClickCloseSchedulePopup(){
      this.isSchedulePopup = false;
    },
    onClickCompleteSchedulePopup(scheduleData){
      this.isSchedulePopup = false;
    },
  }
}
</script>

<style scoped>

.item_day dl{display:block;border-radius:4px;font-weight:700;font-size:13px;line-height:16px;letter-spacing:-.02em}
.item_day dl:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.item_day dl dt{float:left}
.item_day dl dt .icon_firfin{padding:3px 3px 2px;border-radius:5px}
.item_day dl dd{overflow:hidden;padding:2px 0 2px 8px;text-align:left}
.item_day dl.disabled{opacity:0.3;}

.item_day dl.item_total{padding:4px 8px 3px 6px;background-color:#ffeb94;color:#555}
.item_day dl.item_total dd{padding:0 0 0 4px;text-align:right}

.item_day dl + dl{margin-top:8px}
</style>